import $ from 'jquery'
import { enableSmoothScrolling } from './_smooth_scrolling'
import { unslidify } from './_unslider'
import './_jquery.isonscreen'

unslidify($)

function applyEvents (element, events) {
  var $el = (element instanceof ($))
    ? element
    : $(element)
  var keys = Object.keys(events)
  var i
  for (i = 0; i < keys.length; i += 1) {
    $el.on(keys[i], events[keys[i]])
  }
}

function prepareSliderA11y (element) {
  var $element = (element instanceof ($))
    ? element
    : $(element)

    // setting up slider events for all sliders
    // do some a11y magic with aria-hidden for not confusing users of assistive technology
    // and do so before initializing the slider because events and stuff
  $element.on('unslider.ready', function (event) {
    var $slider = $(event.target)
    var $slides = $slider.find('.slide')

    $slider.data('$slides', $slides)

    $slides.each(function () {
      var $this = $(this)

      if (!$this.is('.unslider-active')) {
        $this.attr('aria-hidden', 'true')
      }
    })
  }).on('unslider.change', function (event, index, slide) {
    var $slider = $(event.target)
    var $slides = $slider.data('$slides')
    var $slide = $(slide)
    // hide non active slides for assistive technology
    $slides.attr('aria-hidden', 'true')
    $slide.removeAttr('aria-hidden')

    // announce new slide for a11y api
    $slides.removeAttr('aria-live')
    $slide.attr('aria-live', 'polite')
  })
}

function makeSlider (selector, options, events) {
  var $el = $(selector)
  if (events) {
    applyEvents($el, events)
  }
  return $el.unslider(options)
}

function makeSliderNavi (slider, events) {
  var $slider = (slider instanceof ($)
    ? slider
    : $(slider))
  var $sliderNav = $('#' + $slider.data('navigated-by'))

  if ($sliderNav.length === 0) {
    return false
  }
  applyEvents($sliderNav, events)
  return true
}

function createSkillsSlider (selector) {
  var $window = $(window)
  var $skillSlider
  var events
  var skillSliderTimeoutId

  events = {
    'unslider.ready': function () {
      var $slider = $(this)
      var $slides = $slider.find('.further-skills-collection')
      var max = 0
      $slides.each(function () {
        var $this = $(this)
        max = ($this.outerHeight() > max)
          ? $this.outerHeight()
          : max
      })
      $slider.css('height', max)
    }
  }

  $skillSlider = makeSlider(selector, {
    nav: false,
    arrows: false,
    animation: 'fade'
  }, events)

  events = {
    click: function (event) {
      var $target = $(event.target)
      var $buttons = $target.closest('ul').find('button')
      $buttons.removeClass('active')
      if ($target.is('[data-target="#libraries"]')) {
        $skillSlider.unslider('animate:0')
      }
      if ($target.is('[data-target="#tools"]')) {
        $skillSlider.unslider('animate:1')
      }
      if ($target.is('[data-target="#languages"]')) {
        $skillSlider.unslider('animate:2')
      }
      $target.addClass('active')
    }
  }

  makeSliderNavi($skillSlider, events)

  // I don't want slider layout to break on viewport changes, so I need to reinitialize
  $window.on('resize', function () {
    clearTimeout(skillSliderTimeoutId)
    skillSliderTimeoutId = setTimeout(function () {
      $skillSlider.init().trigger('unslider.ready')
    }, 100)
  })

  return $skillSlider
}

function createProjectSlider (selector) {
  var $window = $(window)
  var $projectSlider
  var projectSliderTimeoutId

  $projectSlider = makeSlider(selector, {
    nav: false,
    arrows: {
      prev: '<button class="unslider-arrow prev"><span class="visuallyhidden">Zurück</span></button>',
      next: '<button class="unslider-arrow next"><span class="visuallyhidden">Weiter</span></button>'
    }
  })

  // I don't want slider layout to break on viewport changes, so I need to reinitialize
  $window.on('resize', function () {
    clearTimeout(projectSliderTimeoutId)
    projectSliderTimeoutId = setTimeout(function () {
      $projectSlider.init().trigger('unslider.ready')
    }, 100)
  })

  return $projectSlider
}

// @https://remysharp.com/2010/07/21/throttling-function-calls
function throttle (fn, threshhold, scope) {
  threshhold || (threshhold = 250)
  var last,
    deferTimer
  return function () {
    var context = scope || this
    var now = +new Date()
    var args = arguments
    if (last && now < last + threshhold) {
      clearTimeout(deferTimer)
      deferTimer = setTimeout(function () {
        last = now
        fn.apply(context, args)
      }, threshhold)
    } else {
      last = now
      fn.apply(context, args)
    }
  }
}

function triggerAnimation () {
  var $els = $('.about article, .primary-skill')
  $els.each(function () {
    var $el = $(this)
    if ($el.isOnScreen(0, 0.1)) {
      $el.addClass('triggered')
    }
  })
};

function init () {
  var $body = $('body')

  // I want to know in CSS if I've got JS ready
  $('html').removeClass('no-js').addClass('js')

  // Smooth scrolling on anchors for nicer ux
  enableSmoothScrolling()

  // I want my sliders ready, so I need to set them up
  prepareSliderA11y($body)
  createSkillsSlider('.skills-slider')
  createProjectSlider('.project-slider')

  $(window).on('resize orientationchange load', triggerAnimation)
  // I don't want my scroll events to fire too often, so i can use debouncing
  $(window).on('scroll', throttle(triggerAnimation, 200))
}

$(function () {
  init()
})
